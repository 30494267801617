<template>
  <div>
    <nav-bar />
    <debate-hero />
    <debate />
    <debate-tabs />
    <Footer />
  </div>
</template>

<script>
import DebateHero from "../components/New/Debates/Debate-hero.vue";
import Debate from "../components/New/Debates/Debate.vue";
import DebateTabs from "../components/New/Debates/DebateTabs.vue";
import Footer from "../components/New/Footer.vue";
import NavBar from "../components/New/NavBar.vue";
export default {
  components: {
    NavBar,
    Footer,
    DebateHero,
    Debate,
    DebateTabs,
  },
};
</script>

<style lang="scss" scoped>
</style>