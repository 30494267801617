<template>
  <div class="container-fluid debate py-5">
    <div class="row">
      <div class="col-md-7">
        <small class="my-5">Published by {{ trendingDebates.author }}</small>
        <h2 class="py-3"><b>{{trendingDebates.topic}}</b></h2>
        <h5><b>Thesis</b></h5>
        <p>
          {{trendingDebates && trendingDebates.body.slice(0,400)}} ... <router-link :to="`debates/${trendingDebates.id}`">read more</router-link>
        </p>

        <!--  <h5><b>Thesis</b></h5>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s...
        </p> -->

        <div class="row py-3">
          <div class="col-md-6">
            <router-link :to="`debates/${trendingDebates.id}/add_lead_opinion`" class="download_btn">
              Add Lead Opinion
            </router-link>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6 d-flex justify-content-end action">
                <button class="iconify like">
                  <i class="far fa-thumbs-up"></i>
                </button>
              </div>

              <div class="col-md-6 action">
                <button
                  class="iconify dislike"
                >
                <i class="far fa-thumbs-down"></i>
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-md-4 offset-md-1">
          <img :src="trendingDebates.image" class="img-responsive" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import debateAuthors from '../../../helpers/debates'

export default {
  data() {
    return {
      trendingDebates: {}
    }
  },

  methods: {

    fetchDebates() {
      this.$store.dispatch('fetchApprovedDebateArticle')
          .then( response => {
              const data = response.data
             
              const result = debateAuthors.map(item => {
                  const obj = data.find(o => o.id === item.id);
                  return { ...item, ...obj };
              });
                
              this.trendingDebates = result[1]
                
          })
          .catch(err => {
              
              if(err?.response?.data == undefined) {
              this.toast({
                  message:'Network error. Check network connection and try again.',
                  type:'error'
              })
          }
          })
    }

  },

  created() {
    this.fetchDebates()
  }

};
</script>

<style  scoped>
.debate {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}

.iconify {
  font-size: 28px;
  line-height: 1em;
  color: #fff;
  padding: 6px;
  border:none;
}
.iconify:hover, .iconify:focus, .iconify:active{
  border:none;
}

.dislike {
  background: #f4a639;
}

.like {
  background: #60e083;
}

.img-responsive {
    width: 100%;
    height: 100%;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 20px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
</style>