<template>
  <div class="container-fluid related_debates">

    <div class="row pt-5">
      <div class="">
        <h2 class="my-5">Sorry, No Debate arhived</h2>
      </div>
      <!-- <div class="col-md-6">
        <img
          src="../../../assets/new/testing-img.jpg"
          class="img-responsive"
          alt=""
        />
        <h4 class="py-3">
          Africa’s Telecom and Technology Industry Leads the Charge against
          COVID-19
        </h4>

        <h5 class="py-2"><b>Thesis</b></h5>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s...
        </p>

        <h5 class="py-2"><b>Anti-Thesis</b></h5>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s...
        </p>

        <div class="row py-2">
          <div class="col-md-6">
            <button class="btn btn-outline-dark mb-3 btn-small">
              View Resolution
            </button>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6 d-flex justify-content-end action">
                <span
                  class="iconify like"
                  data-icon="bx:bx-like"
                  data-inline="false"
                ></span>
              </div>

              <div class="col-md-6 action">
                <span
                  class="iconify dislike"
                  data-icon="bx:bx-dislike"
                  data-inline="false"
                ></span>
              </div>
            </div>
          </div> 

        </div>
        
      </div>
      -->
    </div>
<!--       
    <div class="row pt-5">
      <div class="col-md-12 text-center">
        <button class="btn view_more">View more</button>
      </div>
    </div> -->

  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.img-responsive {
  width: 100%;
  height: 40%;
}

.iconify {
  font-size: 38px;
  line-height: 1em;
  color: #fff;
  padding: 6px;
}

.dislike {
  background: #f4a639;
}

.like {
  background: #60e083;
}

.view_more {
  border: 1px solid rgba(0, 0, 0, 0.7);
  outline: none;
  transition: all 0.5s;
}
.view_more:focus,
.view_more:active,
.view_more:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}
</style>